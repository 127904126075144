<template>
  <div v-if="!loading" class="d-flex flex-nowrap">
    <FrequencyButton
      v-for="index in 3"
      :key="index"
      :frequency="index"
      :active="frequency >= index"
      :disabled="readonly"
      @click="setFrequency(index)"
    ></FrequencyButton>
  </div>
  <div v-else>
    <DataLoader :custom-loading-trigger="loading"></DataLoader>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import FrequencyButton from '@/components/activities/FrequencyButton.vue'
import DataLoader from '@/components/common/DataLoader.vue'

export default {
  name: 'Frequency',
  components: { DataLoader, FrequencyButton },
  props: {
    selection: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      frequency: 1
    }
  },
  watch: {
    selection: {
      immediate: true,
      handler(selection) {
        this.frequency = selection.frequency
      }
    }
  },
  methods: {
    ...mapMutations('page', ['setDataLoadingEnabled']),
    ...mapActions('activities', ['updateJobOfferActivitySelection']),
    setFrequency(frequency) {
      this.loading = true
      this.setDataLoadingEnabled(false)
      this.frequency = frequency
      this.updateJobOfferActivitySelection({
        id: this.selection.id,
        data: {
          frequency,
          job_offer_version_id: this.selection.job_offer_version_id
        }
      }).finally(() => {
        this.setDataLoadingEnabled(true)
        this.loading = false
      })
    }
  }
}
</script>

<style scoped></style>
