<template>
  <v-btn
    icon
    small
    class="mr-1 nowrap"
    :elevation="disabled ? 0: 1"
    :class="{'frequency-button--disabled' : disabled}"
    :color="active ? 'primary' : 'gray'"
    @click="select"
  >
    <v-icon v-if="active"> mdi-briefcase-variant </v-icon>
    <v-icon v-else> mdi-briefcase-variant-outline </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'FrequencyButton',
  props: {
    active: {
      type: Boolean,
      required: false,
      default: true
    },
    frequency: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    select() {
      if (this.disabled) return
      this.$emit('click')
    }
  }
}
</script>

<style scoped>
.frequency-button--disabled {
  pointer-events: none;
}
</style>
