<template>
  <div>
    <PageTitle
      icon="mdi-briefcase"
      :title="title"
      back-button
      print-button
    ></PageTitle>

    <v-row>
      <v-col class="pt-0">
        <div v-if="unsaved">
          {{ $t("job_offers.activities.unsaved") }}
        </div>
        <ActivitiesHeader
          v-if="jobOffer"
          :with-lock-icon="disabled"
          :title="jobOffer.name"
          :subtitle="activitiesSubtitle"
          :job-parent="jobOffer?.employer.name"
        />

        <v-card class="mt-6 pa-8 print-no-spacing">
          <v-row no-gutters class="mb-5 d-print-none">
            <v-col
            ><ComponentTitleWithDataLoader></ComponentTitleWithDataLoader>
            </v-col>
            <v-spacer/>
            <v-col class="text-right">
              <EditActivitiesDialog
                :previous-selection="offerActivitySelections"
                :disabled="disabled"
                @addAndRemoveActivitySelections="addAndRemoveActivitySelections"
              />
            </v-col>
          </v-row>

          <ExplicitActivitiesTable
            v-if="!dataIsLoading"
            :activity-selections="offerActivitySelections"
            :variant="Variant.Offer"
            :disabled="disabled"
            @removeSelection="removeSelection"
            @nameChanged="refreshJobOffer"
          >
            <template v-slot:frequencyColumn="{ item }">
              <Frequency :selection="item" :readonly="disabled"></Frequency>
            </template>
            <template v-slot:levelColumn="{ item }">
              <TrainingLevel :selection="item" :readonly="disabled"></TrainingLevel>
            </template>

            <template v-slot:actionsColumn="{ item }">
              <DeleteActivityDialog
                :selection="item"
                :disabled="disabled"
                @removeSelection="removeSelection"
              ></DeleteActivityDialog>
            </template>
          </ExplicitActivitiesTable>
        </v-card>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import ExplicitActivitiesTable, {
  Variant
} from '@/components/activities/ExplicitActivitiesTable.vue'
import EditActivitiesDialog from '@/components/activities/EditActivitiesDialog.vue'
import Frequency from '@/components/activities/Frequency.vue'
import TrainingLevel from '@/components/activities/TrainingLevel.vue'
import eventBus from '@/eventBus'
import PageTitle from '@/components/layout/PageTitle.vue'
import ComponentTitleWithDataLoader from '@/components/layout/ComponentTitleWithDataLoader.vue'
import ActivitiesHeader from '@/components/common/ActivitiesHeader.vue'
import DeleteActivityDialog from '@/components/activities/DeleteActivityDialog.vue'

export default {
  name: 'Activities',
  components: {
    ActivitiesHeader,
    ComponentTitleWithDataLoader,
    DeleteActivityDialog,
    PageTitle,
    TrainingLevel,
    Frequency,
    ExplicitActivitiesTable,
    EditActivitiesDialog,
  },
  data() {
    return {
      Variant
    }
  },
  computed: {
    ...mapGetters('page', ['dataIsLoading']),
    ...mapState('jobOffers', ['jobOffer']),
    ...mapState('professions', ['professions']),
    jobOfferId() {
      return this.$route.params.id
    },
    unsaved() {
      return !this.jobOfferId
    },
    offerActivitySelections() {
      const versions = this.jobOffer?.versions
      if (versions) {
        const version = versions[0]
        if (version) {
          return version.jobofferactivityselection_set
        }
      }
      return []
    },
    disabled() {
      return this.unsaved || this.jobOffer?.status[0] === "MATCHING" || this.jobOffer?.status[0] === "ARCHIVED"
    },
    activitiesSubtitle() {
      if (this.disabled) {
        return this.$t("job_offers.activities.non_editable", {'status': this.jobOffer?.status[1]})
      }
      return this.$t("job_offers.activities.editable", {'status': this.jobOffer?.status[1]})
    },
    title() {
      if (this.jobOfferId) {
        return `${this.$t('global.offer')} ${this.jobOfferId} - ${this.$t('global.activities')}`
      }
      return `${this.$t('global.offer')} - ${this.$t('global.activities')}`
    }
  },
  mounted() {
    this.setManualDataIsLoading(true)
    this.getImplicitActivities().then(() => {
      // Getting ~700 professions is quite slow, so do only on full refresh
      if (this.professions.length === 0) {
        this.getProfessions().then(() => {
          this.refreshJobOffer()
          this.setManualDataIsLoading(false)
        })
      } else {
        this.refreshJobOffer()
        this.setManualDataIsLoading(false)
      }
    })
  },
  methods: {
    ...mapMutations('page', ['setManualDataIsLoading']),
    ...mapActions('activities', [
      'getImplicitActivities',
      'setInitialSelectedExplicitActivities'
    ]),
    ...mapActions('professions', ['getProfessions']),
    ...mapActions('jobOffers', ['getJobOffer']),
    ...mapActions('activities', [
      'createJobOfferActivitySelection',
      'removeJobOfferActivitySelection',
      'removeJobOfferActivitySelections',
    ]),
    refreshJobOffer() {
      if (this.jobOfferId) {
        this.getJobOffer(this.jobOfferId).then(() => {
          this.setInitialSelectedExplicitActivities(this.offerActivitySelections)
        })
      }
    },
    addAndRemoveActivitySelections(addedActivities, removedSelections) {
      this.createJobOfferActivitySelection({
        "items": addedActivities.map(activity =>
          ({
            activity_id: activity,
            frequency: 1,
            effort: 1,
            job_offer_version_id: this.jobOffer.versions[0].id
          })
        )
      }).then(() => {
        this.removeJobOfferActivitySelections(
          removedSelections.map(selection => selection.id)
        ).then(() => {
          this.refreshJobOffer()
          eventBus.$emit('closeDialog')
        })
      })
    },
    removeSelection(selectionId) {
      this.removeJobOfferActivitySelection(selectionId).then(() => {
        this.refreshJobOffer()
        eventBus.$emit('closeDeleteDialog')
      })
    }
  }
}
</script>

<style lang="scss"></style>
